import React, {useEffect, useState} from "react";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';
import Avatar from '@mui/material/Avatar';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Snackbar from '@mui/material/Snackbar';
import {useLocation, useNavigate, useParams} from "react-router-dom";
import FormHelperText from '@material-ui/core/FormHelperText';
import {PaymentPendingPage} from "../../../Component/PaymentPending/PaymentPendingPage";
import Card from '@mui/material/Card';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import './registrationStyle.css';
import FormGroup from '@material-ui/core/FormGroup';
import ErrorIcon from '@mui/icons-material/Error';
import {
    getEventDetails,
    getEventDetailsGeneral,
    makeCompCurlecPayment,
    makeCompPayment,
    registerCompetitor
} from "../../../services/AWS/competitionInformation/CompetitionService";
import {Auth} from 'aws-amplify';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import {useAuth} from "../../../services/WCAAuth/WCAAuthService";
import {useDispatch, useSelector} from "react-redux";
import {numberWithCommas} from "../../../utils/math";
import CardMedia from "@mui/material/CardMedia";
import Profile_background from "../../membership/portal/image/Profile_background.jpeg";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import moment from "moment";
import {getCountryCode, getUserinfo} from "../../../services/AWS/user/user";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import tshirtSizeImage from "./tshirtsize_usm.jpeg";
import Box from "@mui/material/Box";
import {sessionDataActions} from "../../../store";
import {CompetitionMenuItem, EventIconComponent} from '../../../Component/Competition/CompetitionComponent';
import {getWcaPerson} from "../../../services/AWS/Registration/RegistrationService";
import {GetMYCSAEventCode, GetWCAEventCode, WCAEventCode} from "../../../utils/constant";
import alipayLogo from "./image/alipay-logo.png";
import mastercardLogo from "./image/mastercard-logo.png";
import visaLogo from "./image/visa-logo.png";
import unionPayLogo from "./image/unionpay-logo.jpg";
import fpxLogo from "./image/FPX-Logo.png";
import {CompNavBtnLink} from "../../../Component/masterPagecomponent/NavbarElements";

const arrayContains = (array, value) => {
    if (!array){
        return false;
    }
    for (let i = 0; i < array.length; i++) {
        if (array[i] === value) {
            return true;
        }
    }
    return false;

}

const AcUseRankingEvent = ["4x4x4 Blindfolded","5x5x5 Blindfolded","3x3x3 Multi-Blind","3x3x3 Fewest Moves"];
const topNLimitEvent ={
    "4x4x4 Blindfolded": 50,
    "5x5x5 Blindfolded": 50,
    "3x3x3 Multi-Blind": 40,
    "3x3x3 Fewest Moves": 60,
}



const RegistrationPage = (props) => {
    const dispatch = useDispatch();
    const {justLoggedIn, signedIn, signIn, signOut, loadingAuth} = useAuth();
    const [errorMessages, setErrorMessages] = useState({});
    const [totalAmouuntToPay, setTotalAmountToPay] = useState(0);
    const [compDetails, setcompDetails] = useState([]);
    const [ShowNotification, setShowNotification] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState("");
    const [eventList, setEventList] = useState([]);
    const [EventToParticipate, setEventToParticipate] = useState(new Map());
    const [WCAConfirm, setWCAconfirm] = useState(false);
    const [guestNo, setGuestNo] = useState(0);
    const [paperCert, setPaperCert] = useState(false);
    const [tshirtSize, setTshirtSize] = useState("none");
    const [compLocation, setCompLocation] = useState("none");
    const [userInfo, setUserInfo] = useState(null);
    const authToken = useSelector(state => state.sessionData.authToken);
    const isLoggedIn = useSelector(state => state.sessionData.isLoggedIn);
    const user = useSelector(state => state.sessionData.userInformation);
    const isWCALoggedin = useSelector(state => state.sessionData.isWCALoggedIn);
    const WCAuserLoggedInCheck = useSelector(state => state.sessionData.WCAuserLoggedInCheck);
    const [displayPendingPayment, setDisplayPendingPayment] = useState(false);
    const [currentCurlecPaymentData, setCurrentCurlecPaymentData] = useState(null);
    const [waitingListWarning, setWaitingListWarning] = useState(false);
    const [eligibleEvent, setEligibleEvent] = useState(new Map());
    const [PostRegisterEdit, setPostRegisterEdit] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(null);
    const [timeslot, setTimeslot] = useState("none");
    const [isNewComer, setIsNewComer] = useState(false);
const [curlecSelected, setCurlecSelected] = useState(false);
const [AuthorizedCheckIn, setAuthorizedCheckIn] = useState(false);
const [Country, setCountry] = useState("MY");

    const tshirtComp =["PenangCubeMasters2024","test","MalaysiaCubeChampionship2023","SunwayVelocityMallOpen2024","WCAAsianChampionship2024","MalaysiaCubeChampionship2024","WarmUpatgatewayklia22024"];
    const midnightComp = ["SleeplessinSelangor2025","test"]
    const LocationSelectionComp = ["test","MalaysiaFMCChampionship2024"]
    const newComerComp = ["test","MalaysiaCubeChampionship2024","MalaysiaNewComer2025"];
    const localStorageKey = (key) => `groups.${process.env.REACT_APP_WCA_OAUTH_CLIENT_ID}.${key}`;
    const getLocalStorage = (key) => localStorage.getItem(localStorageKey(key));
    const signOutMYCA = async () => {
        try {
            await Auth.signOut();
            //go to current page with params to remove MYCALoggedIn
            window.location = `${window.location.href}\?MYCALoggedIn=true`;
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }
    const signOutWCA = async () => {
        await signOut();
    }

    const FindPriceInEventList = (event) => {
        for (let i = 0; i < eventList.length; i++) {
            if (eventList[i].name.split(":")[0] === event) {
                return eventList[i].name.split(":")[1];
            }
        }
        return 0;
    }
    const MYCAToken = getLocalStorage("MYCAaccessToken");

    const setUserInformation = async (authToken) => {
        dispatch(sessionDataActions.loading());
        const userInfo = await getUserinfo(authToken)
        setUserInfo(userInfo);


        dispatch(sessionDataActions.loaded());
    }
    useEffect(() => {
            if (isLoggedIn && authToken) {
                setUserInformation(authToken);
            }
        }, [authToken]
    )
    useEffect(() => {
        //calculate total amount to pay
        let totalAmount = 0;
        //is event to participate is empty, return
        if (EventToParticipate.size !== 0) {
            for (let [key, value] of EventToParticipate) {
                if (value === true) {
                    if (eligibleEvent.has(key) && eligibleEvent.get(key) === false) {
                        setEventToParticipate(new Map(EventToParticipate.set(key, false)));
                        continue;
                    }
                    totalAmount += parseInt(FindPriceInEventList(key));
                }
            }
        }
        else{
            //set EventToParticipate if compDetails.events_participated is not null, example data is 2x2x2,3x3x3, then set the event to participate
            if (compDetails.hasOwnProperty("events_participated") && compDetails.events_participated !== null) {
                const eventList = compDetails.events_participated.split(",");
                for (let i = 0; i < eventList.length; i++) {
                    setEventToParticipate(new Map(EventToParticipate.set(eventList[i], true)));
                }
            }
        }
        if (compDetails.hasOwnProperty("tshirtSize")) {
            setTshirtSize(compDetails.tshirtSize);
        }
        if (compDetails.hasOwnProperty("compLocation")) {
            setCompLocation(compDetails.compLocation);
        }
        if (compDetails.hasOwnProperty("PaperCertificate") && compDetails.PaperCertificate === "1") {
            setPaperCert(true);
        }
        if (userInfo  === null || !userInfo.hasOwnProperty("tier") || userInfo.tier !== "Premium") {
            totalAmount +=  parseInt(compDetails.Admin_charges);
        }
        totalAmount += parseInt(compDetails.Guest_charges) * parseInt(guestNo);
        totalAmount += parseInt(compDetails.Basic_charges);
        setTotalAmountToPay(totalAmount);


    }, [EventToParticipate, compDetails,guestNo,userInfo]);
    const renderErrorMessage = (name) =>
        name === errorMessages.name && (
            <div style={{fontSize:20}} className="error">{errorMessages.message}</div>
        );
    useEffect(() => {
        if (WCAuserLoggedInCheck === true) {
            GetCompetitionDetails(id);
        }
    }, [WCAuserLoggedInCheck]);
    useEffect(() => {
        if (justLoggedIn === true) {
            refreshPage();
        }

    }, [justLoggedIn]);
    const handleClose = (event, reason) => {
        setShowNotification(false);
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    }

const GetCompetitionDetails = async (comp_id) => {
    dispatch(sessionDataActions.loading());
    let getcompdetails;
        if (isWCALoggedin) {
            getcompdetails = await getEventDetails(comp_id, user.email);
            if (user.country.id === "China" && isAC2024Path) {
                setpaymentMethod("stripe");
            }

            if (user.wca_id !== null && user.wca_id !== undefined && !user.wca_id.includes(moment().year().toString())) {
                setIsNewComer(false);
            }
            else{
                setIsNewComer(true);
            }

            //loop Register_Access
            if (getcompdetails.hasOwnProperty("Register_Access")) {
                const auth_list = getcompdetails.Register_Access.toString().split(";");
                for (var i = 0; i < auth_list.length; i++) {
                    if (auth_list[i] === user.email) {
                        setAuthorizedCheckIn(true);
                        break;
                    }
                }
            }

        }
        else{
            getcompdetails = await getEventDetailsGeneral(comp_id);
        }



        if (getcompdetails != undefined && getcompdetails.Events != null) {
            const transformEvents = await getcompdetails.Events_price.split(",").map((item) => SetCompetitionList(item));
            setEventList(transformEvents);
            //set qualification
            if (user !== null &&  user !== undefined && user.wca_id !== null && user.wca_id !== undefined && user.personal_records !== null && user.personal_records !== undefined) {
                const personRecord = user.personal_records;
                //example of qualificationconfig is 3x3x3:single:60;4x4x4:single:60,convert to map, the field from getcompdetails.qualification
                const qualificationConfig =getcompdetails.hasOwnProperty("qualification")? getcompdetails.qualification.split(";"):[];
                const qualificationMap = new Map();
                for (let i = 0; i < qualificationConfig.length; i++) {
                    const config = qualificationConfig[i].split(":");
                    qualificationMap.set(config[0], {type:config[1],method:config[2],value:Number(config[3])} );
                }
                const localEligibleEvent = new Map();
                //compare the qualification with the person record
                for (let [key, value] of qualificationMap) {
                    try {
                        const eventRecord = personRecord[GetWCAEventCode[key]];
                        if (eventRecord === null || eventRecord === undefined) {
                            localEligibleEvent.set(key, false);
                            continue;
                        }
                        //some scene the competitor didn't have average or single result, so need to check
                        if (!eventRecord.hasOwnProperty(value.type)) {
                            localEligibleEvent.set(key, false);
                            continue;
                        }
                        let resultValue = value.method === "score" ? eventRecord[value.type].best : eventRecord[value.type].world_rank;
                        // only convert if the event is score, if ranking just direct compare
                        if (value.method === "score"){
                            // convert value if event is multi blind
                        if (value.type === "single" && key === "3x3x3 Multi-Blind" ) {
                            if (resultValue === 0 || resultValue === null) {
                                localEligibleEvent.set(key, false);
                                continue;
                            }
                            resultValue = 99 - Math.floor(resultValue / 10000000);
                            //inverted for multi blind
                            if (resultValue > value.value) {
                                localEligibleEvent.set(key, true);
                            } else {
                                localEligibleEvent.set(key, false);
                            }
                            continue;
                        }
                        //handle for fmc average because i dunno why the wca api return in *100 format different from single lol
                        if (value.type = "average" && key === "3x3x3 Fewest Moves") {
                            if (resultValue === null || resultValue === undefined || resultValue <= 0) {
                                localEligibleEvent.set(key, false);
                                continue;
                            }
                            resultValue = resultValue / 100;
                        }
                        }
                        if (resultValue < value.value) {
                            localEligibleEvent.set(key, true);
                        } else {
                            localEligibleEvent.set(key, false);
                        }
                    }
                    catch (e) {
                        //whenever got error, set the event to false
                        localEligibleEvent.set(key, false);
                        console.log(e);

                    }
                }
                setEligibleEvent(localEligibleEvent);

            }
            else{
                //disqualify events that have qualificationConfig
                const qualificationConfig =getcompdetails.hasOwnProperty("qualification")? getcompdetails.qualification.split(";"):[];
                const qualificationMap = new Map();
                const localEligibleEvent = new Map();
                for (let i = 0; i < qualificationConfig.length; i++) {
                    const config = qualificationConfig[i].split(":");
                    qualificationMap.set(config[0], {type:config[1],method:config[2], value:Number(config[3])} );
                }
                //compare the qualification with the person record
                for (let [key, value] of qualificationMap) {
                        localEligibleEvent.set(key, false);
                }
                setEligibleEvent(localEligibleEvent);

            }
        }
        //transform base charge
        if (getcompdetails.Base_charges !== null && getcompdetails.Base_charges !== undefined) {
            const baseChargeArray = getcompdetails.Base_charges.split(";");
            getcompdetails.Basic_charges = 0;
            getcompdetails.Admin_charges = 0;
            getcompdetails.EventCharge = 0;
            getcompdetails.Guest_charges = 0;
            //loop base charge array
            for (let i = 0; i < baseChargeArray.length; i++) {
                const charge = baseChargeArray[i];
                if (charge.includes("Base_charges:")) {
                    getcompdetails.Basic_charges = charge.split(":")[1];
                } else if (charge.includes("Admin_charges:")) {
                    getcompdetails.Admin_charges = charge.split(":")[1];
                } else if (charge.includes("EventCharge:")) {
                    getcompdetails.EventCharge = charge.split(":")[1];
                } else if (charge.includes("Guest_charges:")) {
                    getcompdetails.Guest_charges = charge.split(":")[1];
                }
            }
        }
        //transform Actual charge
        if (getcompdetails.chargeDetails !== null && getcompdetails.chargeDetails !== undefined) {
            const baseChargeArray = getcompdetails.chargeDetails.split(",");
            getcompdetails.Basic_charges_payment = 0;
            getcompdetails.Admin_charges_payment = 0;
            getcompdetails.EventCharge_payment = 0;

            //loop base charge array
            for (let i = 0; i < baseChargeArray.length; i++) {
                const charge = baseChargeArray[i];
                if (charge.includes("Base_charges:")) {
                    getcompdetails.Basic_charges_payment = charge.split(":")[1];
                } else if (charge.includes("Admin_charges:")) {
                    getcompdetails.Admin_charges_payment = charge.split(":")[1];
                } else if (charge.includes("EventCharge:")) {
                    getcompdetails.EventCharge_payment = charge.split(":")[1];
                }
            }
        }
        if( Number(getcompdetails.waiting_list_count) >= Number(getcompdetails.WaitingList_limit)){
            setWaitingListWarning(true);
        }

        //set guest no
        if (getcompdetails.hasOwnProperty("GuestNumber")) {
            setGuestNo(getcompdetails.GuestNumber);
        }
        if (getcompdetails.hasOwnProperty("phoneNumber") && Number.parseInt(getcompdetails.phoneNumber) > 0) {
            setPhoneNumber(getcompdetails.phoneNumber);
        }
        if (getcompdetails.hasOwnProperty("compLocation")) {
            setCompLocation(getcompdetails.compLocation);
        }
        if (getcompdetails.hasOwnProperty("timeSlot")) {
            setTimeslot(getcompdetails.timeSlot);
        }
        setcompDetails(getcompdetails);
        dispatch(sessionDataActions.loaded());
    }

const SetCompetitionList = (event_list_str) => {
    return {name: event_list_str}
};

    const handleCountryChange = (event,value) =>{
        setCountry(value.phone);
    }

    const {id} = useParams();
    props.setCompetitionID(id);

    const action = (
        <React.Fragment>
            <Button color="secondary" size="small" onClick={handleClose}>
                Close
            </Button>
        </React.Fragment>
    );



    const handleWCAconfirm = async (event) => {
        event.preventDefault();

        setWCAconfirm(true);
    }
    const handleSubmit = async (event,guestNo) => {
        event.preventDefault();
        dispatch(sessionDataActions.loading());

        if (EventToParticipate.size == 0) {
            setNotificationMessage("Please select at least one event!");
            setShowNotification(true)
            setErrorMessages({name: "EventList", message: "Please select at least one event!"})
            dispatch(sessionDataActions.loaded());
            return;
        }
        if (arrayContains(tshirtComp,id)&& tshirtSize === "none") {
            setNotificationMessage("Please select T-Shirt Size!");
            setShowNotification(true)
            setErrorMessages({name: "tShirtSize", message: "Please select T-Shirt Size!"})
            dispatch(sessionDataActions.loaded());
            return;
        }

        if (arrayContains(LocationSelectionComp,id) && compLocation === "none") {
            setNotificationMessage("Please select Competition Location!");
            setShowNotification(true)
            setErrorMessages({name: "compLocation", message: "Please select Competition Location!"})
            dispatch(sessionDataActions.loaded());
            return;
        }
        if (arrayContains(newComerComp,id) && timeslot === "none") {
            setNotificationMessage("Please select Competition Timeslot!");
            setShowNotification(true)
            setErrorMessages({name: "timeslot", message: "Please select Competition Timeslot!"})
            dispatch(sessionDataActions.loaded());
            return;
        }

            let haveEventTicked = false;
            for (let [key, value] of EventToParticipate) {
                if (value === true) {
                    haveEventTicked = true;
                    continue;
                }
            }
            if (!haveEventTicked) {
                setNotificationMessage("Please select at least one event!");
                setShowNotification(true)
                setErrorMessages({name: "EventList", message: "Please select at least one event!"})
                dispatch(sessionDataActions.loaded());
                return;
            }
            if (!phoneNumber || phoneNumber === "") {
                setNotificationMessage("Please enter phone number!");
                setShowNotification(true)
                setErrorMessages({name: "phoneNumber", message: "Please enter phone number!"})
                dispatch(sessionDataActions.loaded());
                window.scrollTo(0, 0)
                return;
            }


        // if participant age less than 18, need to have guardian, and enforce gust setting turned on
        if (user !== null && user !== undefined && user.hasOwnProperty("dob") && compDetails.hasOwnProperty("enforceUnderAgeGuestTicket") && compDetails.enforceUnderAgeGuestTicket === true) {
            const dob = moment(user.dob, "YYYY-MM-DD");
            //moment of 2026 oct 30
            const CutOffdate = moment("2024-11-01", "YYYY-MM-DD");
            //compare date
            const age = CutOffdate.diff(dob, 'years');
            if (age < 18 &&Number(guestNo) === 0) {
                setNotificationMessage("Please select at least one guest as guardian as you are under age of  18!");
                setShowNotification(true)
                setErrorMessages({name: "guestNo", message: "Please select at least one guest as guardian as you are under age of 18!"})
                dispatch(sessionDataActions.loaded());
                window.scrollTo(0, 0)
                return;
            }
        }
        else{
        }
        let EventToParticipateString = "";
        let i = 1;
        for (let [key, value] of EventToParticipate) {
            if (value == false) {
                continue;
            }
            if (i == 1) {
                EventToParticipateString = key;
            } else {
                EventToParticipateString += "," + key;
            }
            i++;
        }

        //    const userAuth =await Auth.currentSession();
        //    const AuthInfo = await Auth.currentAuthenticatedUser();
        let authTokenToPass = authToken;
        if (!isLoggedIn) {
            authTokenToPass = "none";
        }
        const Registerrespond = await registerCompetitor(
            id,
            EventToParticipateString,
            guestNo.toString(),
            paperCert ? "1" : "0",
            MYCAToken,
            "",
            authTokenToPass,
            PostRegisterEdit,
            tshirtSize,
            compLocation,
            paymentMethod,
            phoneNumber,
            timeslot);
        if (Registerrespond.hasOwnProperty("paymentInfo")) {
            if (paymentMethod === "stripe") {
                //redirect to stripe payment page
                window.location.href = "/competition/Payment/" + Registerrespond.paymentInfo.clientSecret.client_secret;
            }
            else if (paymentMethod === "curlec") {
                dispatch(sessionDataActions.loaded());
                setDisplayPendingPayment(true);
                const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

                if (!res) {
                    alert('Razropay failed to load!!')
                    dispatch( sessionDataActions.loaded());
                    return
                }
                let paymentInfo = null;
                let timeoutAmount = 600;


                setCurrentCurlecPaymentData(Registerrespond.paymentInfo);
                setDisplayPendingPayment(true);
                paymentInfo = Registerrespond.paymentInfo;


                const options = {
                    "key": process.env.REACT_APP_CURLEC_KEY_ID, // Enter the Key ID generated from the Dashboard
                    "amount": paymentInfo.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                    "currency": paymentInfo.currency,
                    "name": "Malaysia Cube Sport Association",
                    "description": compDetails.Competition_name,
                    "order_id": paymentInfo.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                    "callback_url": `${process.env.REACT_APP_AWSPUBLICAPI}CurlecPaymentRedirect`,
                    "theme": {
                        "color": "#3399cc"
                    },
                    "timeout": timeoutAmount,
                    "prefill": {
                        "email": user.email,
                        "contact": phoneNumber
                    },
                };
                const paymentObject = new window.Razorpay(options);

                paymentObject.open();
            }

        } else {
            if (Registerrespond === "Success!") {
                setShowNotification(true);
                setNotificationMessage("Update Success!");
                setPostRegisterEdit(false);
                refreshPage();
                dispatch(sessionDataActions.loaded());
                return;
            }
            setErrorMessages({name: "EventList", message: Registerrespond})
            setShowNotification(true);
            setNotificationMessage("There is error when register, please try again later!");
            dispatch(sessionDataActions.loaded());
            return;
        }

    }

    function refreshPage() {
        window.location.reload(false);
    }

    const handleEventsCheckboxChange = (event) => {
        setEventToParticipate(new Map(EventToParticipate.set(event.target.name, event.target.checked)));

    }
    const handlePaperCertCheckboxChange = (event) => {
        setPaperCert(event.target.checked);

    }
    const handleTshirtchange = (event) => {
        event.preventDefault();
        setTshirtSize(event.target.value);
    }
    const WCALoginPanel = (props) => {
        return (
            <Card style={{maxWidth: "500px", display: "flex", flexDirection: "column", ...cardStyle}}>
                <p> To proceed/update your registration, please login with your WCA Account</p>
                <p> You are encourage to login before registration open</p>
                <Button style={{
                    maxWidth: "200px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "5%",
                    marginBottom: "10%",
                    fontSize: 15,
                    borderRadius: "10px"
                }} onClick={() => signIn()} variant="contained">Login with WCA Account</Button>
                <div><p style={{fontSize: 18}}> No WCA account? Register Below: </p>
                    <a target="_blank" style={{fontSize: 18}}
                       href={"https://www.worldcubeassociation.org"} rel="noreferrer">Register a WCA Account</a></div>
                <WaitingListWarning compDetails={props.compDetails}/>
            </Card>
        )
    }

    async function displayRazorpay(reopen = false, timeLeft = 600) {
setDisplayPendingPayment(true);
        const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

        if (!res){
            alert('Razropay failed to load!!')
            sessionDataActions.loaded();
            return
        }
        let paymentInfo = null;
        let timeoutAmount = 600;

        if (reopen && currentCurlecPaymentData != null) {
            paymentInfo = currentCurlecPaymentData;
            //cut the timeout to 2 minutes
            timeoutAmount = timeLeft;
        }
else {
            const data = await makeCompCurlecPayment(compDetails.competition_ID, user.email, MYCAToken);
setCurrentCurlecPaymentData(data.respond.paymentData);
            paymentInfo = data.respond.paymentData;
        }

        const options = {
            "key": process.env.REACT_APP_CURLEC_KEY_ID, // Enter the Key ID generated from the Dashboard
            "amount": paymentInfo.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
            "currency": paymentInfo.currency,
            "name": "Malaysia Cube Sport Association",
            "description": compDetails.Competition_name,
            "order_id": paymentInfo.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
            "callback_url":`${process.env.REACT_APP_AWSPUBLICAPI}CurlecPaymentRedirect`,
            "theme": {
                "color": "#3399cc"
            },
            "timeout":timeoutAmount,
            "prefill": {
                "email": user.email,
            },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }
    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.src = src
            script.onload = () => {
                resolve(true)
            }
            script.onerror = () => {
                resolve(false)
            }
            document.body.appendChild(script)
        })
    }


    const handleCompLocationChange = (event) => {
        event.preventDefault();
        setCompLocation(event.target.value);
    }

    const handletimeSlotChange = (event) => {
        event.preventDefault();
        setTimeslot(event.target.value);
    }



    const RegisteredPanel = (props) => {
        return (<Card>
                You have been approved for this competition, thanks!
            <br/>
                {props.compDetails && props.compDetails.hasOwnProperty("allowPostApprovalEdit") && props.compDetails.allowPostApprovalEdit == true  && <Button variant="contained" onClick={() => props.setPostRegisterEdit(true)}>Edit Events</Button>}
                {props.compDetails && (!props.compDetails.hasOwnProperty("allowPostApprovalEdit")|| props.compDetails.allowPostApprovalEdit == false)&& <p>Please email <b>info@mycubeassociation.com</b> if you wish to update your information or events.</p>}
        </Card>
    )
    };
    const PendingApprovePanel = () => {
        return (<Card>
        Your registration is in waiting list, we will aprrove you soon when there's a slot, thanks!
        </Card>)
    };
    const RegisterClosePanel = (props) => {
        return (<Card style={{minWidth: "300px", maxWidth: "500px", ...cardStyle}}>
            <ErrorIcon style={{marginTop:"10%",color:"red"}}/>
            This competition is not open for registration now! <br/>
            Check Details page for more information.
        </Card>)
    };
    const RegisterFullPanel = (props) => {
        return (<Card sx={{ maxWidth:"500px",...cardStyle}}>
            The slots for registration and registration is currently fulled!
        </Card>)
    };

    const RegisterReturnerFullPanel = (props) => {
        return (<Card sx={{ maxWidth:"500px",...cardStyle}}>
            The slots for registration for returner (WCA ID is not 2025) is currently fulled!
        </Card>)
    };

    const [paymentMethod, setpaymentMethod] = React.useState('curlec');

    const handlePaymentMethodChange = (event) => {
        setpaymentMethod(event.target.value);
    };

    const location = useLocation();
    const isAC2024Path = location.pathname === '/RubiksWCAAsianChampionship2024/Home'||location.pathname === '/competition/register/RubiksWCAAsianChampionship2024'||id=="test";

    return (
        <div>
            <Snackbar
                anchorOrigin= {{ vertical: 'top', horizontal: 'center' }}
                open={ShowNotification}
                autoHideDuration={6000}
                onClose={handleClose}
                message={notificationMessage}
                action={action}
            />
            <div style={{marginLeft: "0%"}}>
                <h1>{compDetails.Competition_name}</h1>
                {AuthorizedCheckIn && <NavigationButton url={`/competition/CheckInPanel/${id}`} menuText="Check In Panel"/>}
                <h2>Registration</h2>
                <CompetitionMenuItem competition={id} isAC2024Path={isAC2024Path}/>
                <div className="registerBox">
                    {!waitingListWarning && isWCALoggedin === true && compDetails.registerOpen === true && compDetails.registerStatus !== 3 &&
                        convertNumber(compDetails.ReturnerCount,0) >= 100 && !isNewComer &&
                        <RegisterReturnerFullPanel/>}
                    {waitingListWarning && isWCALoggedin === true && compDetails.registerOpen === true && compDetails.registerStatus !== 3 &&
                        <RegisterFullPanel/>}
                    {compDetails.registerOpen === false && isWCALoggedin === true &&
                        <RegisterClosePanel/>}
                    {((!waitingListWarning && compDetails.registerOpen === true && WCAConfirm && isWCALoggedin && (compDetails.registerStatus === 0 || compDetails.registerStatus === 1) && displayPendingPayment === false) || PostRegisterEdit === true) &&
                        !(convertNumber(compDetails.ReturnerCount,0) >= 100 && !isNewComer) &&
                        <RegistrationPanel
                            isAC2024Path={isAC2024Path}
                            handleSubmit={handleSubmit}
                            compDetails={compDetails}
                            userInfo={userInfo}
                            id={id}
                            tshirtSize={tshirtSize}
                            tshirtComp={tshirtComp}
                            midnightComp = {midnightComp}
                            timeslot={timeslot}
                            LocationSelectionComp={LocationSelectionComp}
                            handletimeSlotChange={handletimeSlotChange}
                            newComerComp={newComerComp}
                            renderErrorMessage={renderErrorMessage}
                            paperCert={paperCert}
                            handlePaperCertCheckboxChange={handlePaperCertCheckboxChange}
                            isLoggedIn={isLoggedIn}
                            EventToParticipate={EventToParticipate}
                            eligibleEvent={eligibleEvent}
                            handleEventsCheckboxChange={handleEventsCheckboxChange}
                            eventList={eventList}
                            totalAmouuntToPay={totalAmouuntToPay}
                            signOutMYCA={signOutMYCA}
                            handleTshirtchange={handleTshirtchange}
                            setGuestNo={setGuestNo}
                            guestNo={guestNo}
                            PostRegisterEdit={PostRegisterEdit}
                            handlePaymentMethodChange={handlePaymentMethodChange}
                            paymentMethod={paymentMethod}
                            handlePhoneNumberChange={handlePhoneNumberChange}
                            phoneNumber={phoneNumber}
                            Country={Country}
                            handleCountryChange={handleCountryChange}
                            handleCompLocationChange={handleCompLocationChange}
                            CompLocation={compLocation}
                        />}

                    {!waitingListWarning && compDetails.registerOpen === true && isWCALoggedin && compDetails.registerStatus === 2 &&
                        <PendingApprovePanel/>}
                    {compDetails.registerOpen === true && isWCALoggedin && compDetails.registerStatus === 3 && PostRegisterEdit === false &&
                        < RegisteredPanel compDetails={compDetails} setPostRegisterEdit={setPostRegisterEdit}/>}
                    {isWCALoggedin !== true && <WCALoginPanel compDetails={compDetails}/>}
                    {!waitingListWarning && compDetails.registerOpen === true && isWCALoggedin === true && !WCAConfirm && (compDetails.registerStatus === 0 || compDetails.registerStatus === 1) &&
                        !(convertNumber(compDetails.ReturnerCount,0) >= 100 && !isNewComer) &&
                        <WCAInfoPanel user={user} handleWCAconfirm={handleWCAconfirm}/>}
                    {displayPendingPayment === true && <PaymentPendingPage displayRazorpay={displayRazorpay}/>}
                </div>
                {displayPendingPayment === false && <div>
                    {isWCALoggedin &&
                        <div>
                            <p><b>WCA Name: { user &&  user.name}</b></p>
                            <Button variant="contained" style={{height: "30px"}} onClick={signOutWCA}>Log Out
                                WCA</Button>
                            <Alert
                                style={{maxWidth: "600px", marginBottom: "5px"}} severity="info"> You need to logout
                                at <a
                                    target="_blank" style={{fontSize: 18}}
                                    href={"https://www.worldcubeassociation.org"} rel="noreferrer">WCA
                                    Website</a> first if you want to switch WCA Account</Alert></div>}
                    <Box sx={{display: {xs: 'none', md: 'flex', marginBottom: "10px"}}}>

                    </Box>
                </div>}
            </div>

        </div>

    )


}

const cardStyle = {
    borderRadius: "20px",
    borderWidth:"5px",
    border:"solid",
    borderColor:"#ffffff",
    fontFamily:"Arial",
    fontSize: "20px",
}

const RegistrationPanel = (props) => {


    return (<Box  sx={{flexGrow: 1}}><Grid container> <Grid item xs={12} md={6}><Card style={{maxWidth: "500px",...cardStyle}}>
        {!props.PostRegisterEdit &&  <p><b>Event Registration</b></p>}
        {props.PostRegisterEdit &&  <p><b>Edit Events</b></p>}
        {props.PostRegisterEdit &&  <p style={{fontSize:15,color:"red"}}>*Update of events is allowed once per hour.</p>}
        <div>
            <form onSubmit={(event) => props.handleSubmit(event, props.guestNo)}>
                {!props.PostRegisterEdit && <table>
                    <tr>
                        <td className="RegisterpaymentTableHeader">Base Fee:</td>
                        <td className="RegisterpaymentTableRow">RM {numberWithCommas(props.compDetails.Basic_charges)}</td>
                    </tr>
                    <tr>
                        <td className="RegisterpaymentTableHeader">Admin Fee:</td>
                        <td className="RegisterpaymentTableRow">RM {(props.userInfo === null || !props.userInfo.hasOwnProperty("tier") || props.userInfo.tier !== "Premium") ? numberWithCommas(props.compDetails.Admin_charges) : 0}</td>
                    </tr>
                    <tr>
                        <td className="RegisterpaymentTableHeader">Entry Fee Per Guest:</td>
                        <td className="RegisterpaymentTableRow">RM {numberWithCommas(props.compDetails.Guest_charges)} * {props.guestNo} =
                            RM {numberWithCommas(props.compDetails.Guest_charges * props.guestNo)} </td>
                    </tr>
                </table>}
                <div className="input-container">
                    <label><b>No. of Guests </b> </label>
                    <select
                        style={{
                            width: "50%",
                            marginBottom: "5px",
                            height: "40px",
                            borderRadius: "20px",
                            fontSize: 20,
                            textAlign: 'center'
                        }}
                        disabled={props.PostRegisterEdit}
                        value={props.guestNo}
                        onChange={(event) => props.setGuestNo(event.target.value)} label="Number">
                        <option value="0">0</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                        <option value="6">6</option>
                        <option value="7">7</option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                    </select>
                    {props.compDetails.enforceUnderAgeGuestTicket === true &&
                        <Alert severity="info">Participant under age of 18 is required to be accompanied by at least one parent/guardian.</Alert>}
                    {props.renderErrorMessage("guestNo")}
                </div>
                <p><b>Country Code + Contact Number </b></p>
                <div className="input-container">
                    <TextField name="Phone" label="Phone Number*" type="number" variant="outlined"
                               value={props.phoneNumber}
                               onChange={props.handlePhoneNumberChange}/>
                    {props.renderErrorMessage("phoneNumber")}
                </div>
                <p style={{fontSize: 15}}>*We would only contact you for emergency issue.</p>
                <div className="input-container">
                    <p><b>Events </b></p>
                    {props.eventList && props.eventList.map((item) => (
                        <EventListSelection singleEvent={item} EventToParticipate={props.EventToParticipate}
                                            eligibleEvent={props.eligibleEvent}
                                            isAC2024Path = {props.isAC2024Path}
                                            handleEventsCheckboxChange={props.handleEventsCheckboxChange}
                                            eligibleEvent={props.eligibleEvent} event={props.event}/>
                    ))}
                    {props.renderErrorMessage("EventList")}
                </div>
                {arrayContains(props.tshirtComp, props.id) &&
                    <FormControl fullWidth>
                        <div className="input-container">
                            <label id="state-input-label"><b>T-Shirt Size</b></label>
                            <select key={"tShirtSize"} labelId="tshirt-input-label"
                                    style={{
                                        width: "50%",
                                        marginBottom: "5px",
                                        height: "40px",
                                        borderRadius: "20px",
                                        fontSize: 20,
                                        textAlign: 'center'
                                    }}
                                    value={props.tshirtSize}
                                    disabled={props.PostRegisterEdit}
                                    onChange={(event) => props.handleTshirtchange(event)} label="tShirtSize">
                                <option disabled={true} value="none">-</option>
                                <option value="2XS">2XS</option>
                                <option value="XS">XS</option>
                                <option value="S">S</option>
                                <option value="M">M</option>
                                <option value="L">L</option>
                                <option value="XL">XL</option>
                                <option value="2XL">2XL</option>
                                <option value="3XL">3XL</option>
                                <option value="4XL">4XL</option>
                                <option value="5XL">5XL</option>
                                <option value="6XL">6XL</option>
                                <option value="7XL">7XL</option>
                            </select>
                            <img style={{width: "100%"}} src={tshirtSizeImage} alt="t shirt cahrt"></img>
                            {props.renderErrorMessage("tShirtSize")}
                        </div>
                    </FormControl>}
                {arrayContains(props.LocationSelectionComp, props.id) &&
                    <div className="input-container">
                        <p><b>Competition Location </b></p>
                        <select key={"CompLocation"} labelId="tshirt-input-label"
                                style={{
                                    width: "50%",
                                    marginBottom: "5px",
                                    height: "40px",
                                    borderRadius: "20px",
                                    fontSize: 20,
                                    textAlign: 'center'
                                }}
                                disabled={props.PostRegisterEdit}
                                value={props.CompLocation}
                                onChange={(event) => props.handleCompLocationChange(event)} label="CompLocation">
                            <option disabled={true} value="none">-</option>
                            <option value="Kuala Lumpur">Kuala Lumpur (Kepong)</option>
                            <option value="Johor Bahru">Johor Bahru</option>
                        </select>
                    </div>
                }
                {arrayContains(props.newComerComp, props.id) &&
                    <div className="input-container">
                        <p><b>Time Slot</b></p>
                        <select key={"timeSlot"} labelId="tshirt-input-label"
                                style={{
                                    width: "50%",
                                    marginBottom: "5px",
                                    height: "40px",
                                    borderRadius: "20px",
                                    fontSize: 20,
                                    textAlign: 'center'
                                }}
                                disabled={props.PostRegisterEdit}
                                value={props.timeslot}
                                onChange={(event) => props.handletimeSlotChange(event)} label="timeSlot">
                            <option disabled={true} value="none">-</option>
                            {props.compDetails.hasOwnProperty("AM") && Number(props.compDetails.AM) < 100 && <option value="AM">Morning</option>}
                            {props.compDetails.hasOwnProperty("PM") && Number(props.compDetails.PM) < 100 &&  <option value="PM">Afternoon</option>}
                        </select>
                    </div>
                }
                <div className="input-container">
                            <p><b>Physical Certificate of Participant? </b></p>
                            <FormControlLabel checked={props.paperCert}
                                              key=""
                                              name="paperCert"
                                              disabled={props.PostRegisterEdit}
                                              onChange={props.handlePaperCertCheckboxChange} control={<Checkbox style={{
                                color: "#00e676"
                            }}/>} label="yes"/>
                            {props.renderErrorMessage("certPaper")}
                            {!props.PostRegisterEdit && <div>
                                <div>
                                    <p><b>Total Amount To Pay: RM {props.totalAmouuntToPay}</b></p>
                                </div>
                                {!props.isLoggedIn &&
                                    <Alert severity="info"> For Malaysian Only: MYCSA Member Status: not logged
                                        in <br/> ( Not
                                        Mandatory to log in) <a
                                            href={`/login?redirect_uri=Competition/register/${props.id}?MYCALoggedIn=true`}>Login
                                            Now</a></Alert>}
                                < WaitingListWarning compDetails={props.compDetails}/>
                            </div>}
                            {props.isAC2024Path && !props.PostRegisterEdit && <div>
                                <h4>Payment Method</h4>
                                <RadioGroup
                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={props.paymentMethod}
                                    onChange={props.handlePaymentMethodChange}
                                >
                                    <FormControlLabel value="curlec" control={<Radio/>}
                                                      label="Credit Card, Malaysia E-Wallets, Fpx"/>
                                    <div className="PaymentImageContainer">
                                        <img className="PaymentImageIcon" src={visaLogo}/>
                                        <img className="PaymentImageIcon" src={mastercardLogo}/>
                                        <img className="PaymentImageIcon" src={unionPayLogo}/>
                                        <img className="PaymentImageIcon" src={fpxLogo}/>

                                    </div>
                                    <FormControlLabel value="stripe" control={<Radio/>} label="alipay"/>
                                    <div className="PaymentImageContainer">
                                        <img className="PaymentImageIcon" src={alipayLogo}/>

                                    </div>
                                </RadioGroup>
                            </div>}
                    {arrayContains(props.midnightComp, props.id) && <FormControlLabel
                        sx={{
                            '& .MuiFormControlLabel-label': {
                                color: 'red'
                            }
                        }}
                        control={
                            <Checkbox
                                required={arrayContains(props.midnightComp, props.id)}
                                name="six"
                            />
                        }
                        label="We will not force competitors to compete. If they feel exhausted or anything they can take a rest after letting organisers know. We might not be to accommodate/guarantee that the competitor will be able to compete in that event but competitor health should be each individual competitors' priority. The organising team will not be held liable for anything self inflicted."
                    />}
                            <div className="button-container">
                                <input style={{marginBottom: "5px", "border-radius": "20px"}} type="submit"/>
                            </div>
                        </div>
                    </form>


                    </div>
                    </Card>
                    </Grid>
        {props.isLoggedIn &&
            <Grid sx={{backgroundColor: "#FFFFFF"}} item xs={12} md={6}>
                <MemberInfoCard
                    userInfo={props.userInfo}
                    signOutMYCA={props.signOutMYCA}
                />
            < /Grid>}
    </Grid>
    </Box>)
};

const EventListSelection = (props) => {
    return (
            <FormControl>
            <FormControlLabel
                    checked={props.EventToParticipate.has(props.singleEvent.name.split(":")[0]) ? props.EventToParticipate.get(props.singleEvent.name.split(":")[0]) : false}
                    key={props.singleEvent.name.split(":")[0]} name={props.singleEvent.name.split(":")[0]}
                    onChange={props.handleEventsCheckboxChange}
                    control={<Checkbox
                        disabled={props.eligibleEvent.has(props.singleEvent.name.split(":")[0]) ? !props.eligibleEvent.get(props.singleEvent.name.split(":")[0]) : false}
                        style={{color: "#00e676",}}/>}
                    label={props.singleEvent.name.split(":")[0] + " (RM" + props.singleEvent.name.split(":")[1] + ")"}/>
                {AcUseRankingEvent.includes(props.singleEvent.name.split(":")[0]) && props.isAC2024Path && <FormHelperText style={{fontSize: 12}}> *limited to results ranked top {topNLimitEvent[props.singleEvent.name.split(":")[0]]} of all participants</FormHelperText>}
                </FormControl>
    )
}

const WaitingListWarning = (props) => {
    const compDetails = props.compDetails? props.compDetails:[];
    const WaitingListWarningText = "*Reminder: You will be placed in the waiting list if slots are fulled after you have made your full payment.Please note that refund of payment will involve a 4% deduction as transaction fee if you are not able to participate in the competition.";
    return (
        <div>
            < div style={{color: "red", fontSize: 15}}>
                {WaitingListWarningText}</div>

            <b style={{fontSize: 18}}> Current Approved/Limit Count: {`${compDetails.approved_count}/${compDetails.Participant_limit}`}</b>
        </div>


    )
}

const MemberInfoCard = (props) => {
    return (
        <Card variant="elevation" sx={{maxWidth: "450px", margin: "2px"}}>
            <CardMedia
                sx={{height: 140}}
                image={Profile_background}
                title="Personal_Info_background"
            />
            <CardContent>
                <Typography variant="h5" component="div">
                    MYCSA Member
                </Typography>
                <table style={{width: "100%"}}>
                    <tr>
                        <td className="MemberInfoHeader"><b>Full Name</b></td>
                        <td className="MemberInfoContent">{props.userInfo && props.userInfo.Name} </td>
                    </tr>
                    <tr>
                        <td className="MemberInfoHeader"><b>Country</b></td>
                        <td className="MemberInfoContent">{props.userInfo && props.userInfo.country} </td>
                    </tr>
                    <tr>
                        <td className="MemberInfoHeader"><b>Link WCA Email</b></td>
                        <td className="MemberInfoContent">{props.userInfo && props.userInfo.WCAemail} </td>
                    </tr>
                    <tr>
                        <td className="MemberInfoHeader"><b>Membership Tier</b></td>
                        <td className="MemberInfoContent">
                            <b>{props.userInfo && props.userInfo.hasOwnProperty("tier") ? props.userInfo.tier : "Normal Member"} </b>
                        </td>
                    </tr>
                </table>
                {(props.userInfo && !props.userInfo.hasOwnProperty("tier") || props.userInfo.tier !== "Premium") &&
                    <Alert severity="warning">You are not Premium Member,Premium Member entitled to Waive Admin Fee,
                        purchase at <a href="/memberProfile">Membership Portal</a></Alert>}
                {props.userInfo && props.userInfo.hasOwnProperty("tier") && props.userInfo.tier === "Premium" &&
                    <Alert severity="info">You are entitled to admin fee waiver</Alert>}
            </CardContent>
            <CardActions>
                <Button onClick={() => props.signOutMYCA()} size="large">Sign Out</Button>
            </CardActions>
        </Card>
    )
}
const WCAInfoPanel = (props) => {
    // display information of the user
    return (
        <Card style={{maxWidth: "500px",...cardStyle}}>
            <h3>Your WCA Information</h3>
            <List sx={{width: '100%', maxWidth: 360, bgcolor: 'background.paper'}}>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>

                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Name" secondary={props.user && props.user.name}/>
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>

                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="WCA ID" secondary={ props.user &&  props.user.wca_id}/>
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>

                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Representing" secondary={  props.user && props.user.country.id}/>
                </ListItem>
                <ListItem>
                    <ListItemAvatar>
                        <Avatar>

                        </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="Date of Birth" secondary={ props.user &&  props.user.dob}/>
                </ListItem>
            </List>
            <CardActions>
                <Button sx={{marginLeft: "auto", marginRight: "auto"}} onClick={props.handleWCAconfirm}
                        variant="contained">Register</Button>
            </CardActions>
        </Card>
    )
}

const NavigationButton = ( props) => {
    const handleButtongClick = (url) => {
        //navigate to the url
        window.location.href = url;
    }
    return (
        <div>
            <CompNavBtnLink
                onClick={() => handleButtongClick(props.url)}>{props.menuText}</CompNavBtnLink>

        </div>
    )
}

const convertNumber = (num,defaultValue) => {
    if (num === undefined || num === null) {
        return defaultValue;
    }
    return Number(num);
}
export default RegistrationPage;